<template>
  <div class="flex flex-col h-screen">
    <div
      class="flex items-center gap-4 justify-center md:justify-start mx-auto w-full max-w-screen-3xl px-6 md:px-10 py-4"
    >
      <nuxt-link v-if="logoRoute" :to="logoRoute">
        <app-image v-bind="logoBinding" />
      </nuxt-link>

      <app-image v-else v-bind="logoBinding" />

      <slot name="append-logo" />
    </div>

    <div class="overflow-auto flex-auto flex pt-0 p-6 sm:p-6">
      <div
        class="mx-auto md:my-auto w-full max-w-[--maxWidth]"
        :style="{ '--maxWidth': `${contentMaxWidth}px` }"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { RouteLocationRaw } from "#vue-router";

withDefaults(
  defineProps<{
    logoRoute?: RouteLocationRaw;
    contentMaxWidth?: number;
  }>(),
  {
    logoRoute: undefined,
    contentMaxWidth: 1000,
  },
);

const logoBinding = {
  class: "w-[125px] md:w-[170px]",
  src: "/images/logo_full_color.svg",
  width: 125,
  alt: "",
};
</script>
